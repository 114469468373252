import Roles from "./roles";

class Permissions {
  static get values() {
    return [
      {
        id: 0,
        name: "Base",
      },
      {
        id: 1,
        name: "Portal Access",
      },
      {
        id: 2,
        name: "Order History",
      },
      {
        id: 3,
        name: "Inventory",
      },
      {
        id: 4,
        name: "Dealer Access",
      },
      {
        id: 5,
        name: "Product Availability",
      },
      {
        id: 6,
        name: "Order Sync",
      },
      {
        id: 7,
        name: "Messages",
      },
      {
        id: 8,
        name: "Product Visibility",
      },
      {
        id: 9,
        name: "Product Data Refresh",
      },
      {
        id: 10,
        name: "Store Locations",
      },
    ];
  }

  static getRoleById(id) {
    return this.values.find((item) => item.id === id);
  }

  static getRoleByName(name) {
    return this.values.find((item) => item.name === name);
  }

  static getDefaultPermissions(role) {
    switch (role) {
      case Roles.values[0].id:
        return [Permissions.values[1].id, Permissions.values[3].id, Permissions.values[5].id, /*Permissions.values[7].id,*/ Permissions.values[8].id, Permissions.values[9].id, Permissions.values[10].id];
      case Roles.values[1].id:
          return [Permissions.values[2].id, Permissions.values[3].id];
      case Roles.values[2].id:
        return [Permissions.values[1].id, Permissions.values[5].id, /*Permissions.values[7].id,*/ Permissions.values[10].id];
      case Roles.values[3].id:
        return [Permissions.values[2].id, Permissions.values[3].id];
      case Roles.values[4].id:
        return [Permissions.values[5].id];
    }
    return [];
  }
}

export default Permissions;
