import actions from "src/modules/user/userActions";

const initialData = {
  loading: false,
  rows: [],
  count: 0,
  user: null,
  error: null,
};

const userReducers = (state = initialData, { type, payload }) => {

  if (type === actions.ACTION_STARTED) {
    return {
      ...state,
      loading: true,
    };
  }

  if (type === actions.FETCH_SUCCESS) {
    return {
      ...state,
      loading: false,
      rows: payload,
      count: payload.count,
    };
  }

  if (type === actions.CREATE_SUCCESS) {
    return {
      ...state,
      loading: false,
    };
  }

  if (type === actions.ACTION_ERROR) {
    return {
      ...state,
      loading: false,
      error: payload.error,
    };
  }

  return state;
};

export default userReducers;
