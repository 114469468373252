import {
  AppBar,
  CssBaseline,
  makeStyles,
  Toolbar,
  useMediaQuery,
  useTheme,
  Box,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import DrawerComponent from 'src/views/layout/DrawerComponent';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import logo from 'src/assets/img/logo_white.png';
import rflogo from 'src/assets/img/logo_rf_white.svg';

import styles from 'src/assets/jss/views/headerStyle';
import { useSelector } from 'react-redux';
import authSelectors from '../../modules/auth/authSelectors';
import { portalBranding } from 'src/config';

const useStyles = makeStyles(styles);

export default function Header() {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const currentUser = useSelector(authSelectors.selectCurrentUser);
  console.log('PORTAL', process.env.PORTAL_BRANDING);

  return (
    <AppBar position="static" className={classes.navbar}>
      <CssBaseline />
      <Toolbar className={classes.toolbar}>
        <Link to="/" className={classes.logoWrap}>
          <img
            src={portalBranding === 'rf' ? rflogo : logo}
            className={classes.logo}
            alt={portalBranding === 'rf' ? 'Rod Forge' : 'TFO'}
          />
        </Link>
        <Box sx={{ flex: 1 }} className={classes.navbox}>
          {isMobile ? (
            <DrawerComponent />
          ) : (
            <div className={classes.navlinks}>
              <Link to="/" className={classes.link}>
                {'Team Portal'.toUpperCase()}
              </Link>
              <div className={classes.description}>
                <h2 className={classes.author}>
                  <PersonIcon className={classes.authorIcon} />{' '}
                  {`${currentUser.name}`.toUpperCase()}
                </h2>
                <Link to="/logout" className={classes.logout}>
                  <ExitToAppIcon className={classes.icon} />{' '}
                  {'Logout'.toUpperCase()}
                </Link>
              </div>
            </div>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
}
