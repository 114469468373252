import { applyMiddleware, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { createBrowserHistory } from "history";
import createRootReducer from "src/modules/reducers";
import initializers from "src/modules/initializers";

const history = createBrowserHistory();

let store;

export function configureStore(preloadedStore?) {
  const middlewares = [
    thunkMiddleware,
    routerMiddleware(history),
  ].filter(Boolean);

  store = createStore(
    createRootReducer(history),
    preloadedStore,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );

  for (const initializer of initializers) {
    initializer(store);
  }
  
  return store;
}

export function getHistory() {
  return history;
}
