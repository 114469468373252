import { createSelector } from "reselect";

const selectRaw = (state) => state.auth;

const selectLoading = createSelector(
  [selectRaw],
  (auth) => auth.loading,
);

const selectLoadingInit = createSelector(
  [selectRaw],
  (auth) => Boolean(auth.loadingInit),
);

const selectCurrentUser = createSelector(
  [selectRaw],
  (auth) => auth.currentUser,
);

const selectError = createSelector(
  [selectRaw],
  (auth) => auth.error,
);

const authSelectors = {
  selectRaw,
  selectLoading,
  selectLoadingInit,
  selectCurrentUser,
  selectError,
};

export default authSelectors;
