import React from "react";
import LoadingComponent from "src/views/shared/LoadingComponent";

export default function CustomLoadable(options) {
  const LazyComponent = React.lazy(options.loader);

  return (props) => (
    <React.Suspense fallback={<LoadingComponent />}>
      <LazyComponent {...props} />
    </React.Suspense>
  );
}
