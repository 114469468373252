import { AuthToken } from "src/modules/auth/authToken";
import service from "src/modules/auth/authService";

const prefix = "AUTH";

const authActions = {
  AUTH_START: `${prefix}_START`,
  AUTH_SUCCESS: `${prefix}_SUCCESS`,
  AUTH_ERROR: `${prefix}_ERROR`,
  AUTH_INIT_SUCCESS: `${prefix}_INIT_SUCCESS`,
  AUTH_INIT_ERROR: `${prefix}_INIT_ERROR`,

  doInit: () => async (dispatch) => {
    try {
      const token = AuthToken.get();
      let currentUser = null;

      if (token) {
        currentUser = await service.fetchMe();
      }

      dispatch({
        type: authActions.AUTH_INIT_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      service.signout();

      dispatch({
        type: authActions.AUTH_INIT_ERROR,
      });
    }
  },

  doLogin: (
    email,
    password,
    rememberMe,
  ) => async (dispatch) => {
    try {
      dispatch({type: authActions.AUTH_START});
      
      let currentUser = null;

      const { token } = await service.signin(email, password);

      AuthToken.set(token, rememberMe);
      currentUser = await service.fetchMe();

      dispatch({
        type: authActions.AUTH_SUCCESS,
        payload: {
          currentUser,
        },
      });
    } catch (error) {
      await service.signout();

      dispatch({
        type: authActions.AUTH_ERROR,
        payload: "Sorry, we don't recognize your credentials",
      });
    }
  },

  doLogout: () => async (dispatch) => {
    try {
      dispatch({ type: authActions.AUTH_START });
      await service.signout();

      dispatch({
        type: authActions.AUTH_SUCCESS,
        payload: {
          currentUser: null,
        },
      });
    } catch (error) {

      dispatch({
        type: authActions.AUTH_ERROR,
      });
    }
  },
};

export default authActions;
