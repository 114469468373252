import UserService from "src/modules/user/userService";

const prefix = "USER";

const userActions = {
  ACTION_STARTED: `${prefix}_ACTION_STARTED`,
  ACTION_ERROR: `${prefix}_ACTION_ERROR`,
  FETCH_SUCCESS: `${prefix}_FETCH_SUCCESS`,
  CREATE_SUCCESS: `${prefix}_CREATE_SUCCESS`,
  
  doFetch: () => async (
    dispatch,
  ) => {
    try {

      dispatch({ type: userActions.ACTION_STARTED });
  
      const data = await UserService.fetchUsers();
  
      dispatch({
        type: userActions.FETCH_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: userActions.ACTION_ERROR, payload: error });
      throw(error);
    }
  },
  
  doCreate: (userData) => async (
    dispatch,
  ) => {
    try {

      dispatch({ type: userActions.ACTION_STARTED });
  
      const data = await UserService.createUser(userData);
  
      dispatch({
        type: userActions.CREATE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: userActions.ACTION_ERROR, payload: error });
      throw(error);
    }
  },
};

export default userActions;
