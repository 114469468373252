import { useState } from "react";
import {
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  makeStyles
} from "@material-ui/core";
import { Link } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";

import styles from "src/assets/jss/views/drawerStyle";

const useStyles = makeStyles(styles);

export default function DrawerComponent() {
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List className={classes.list}>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/" className={classes.link}>Dashboard</Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/user" className={classes.link}>Users</Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/order" className={classes.link}>Orders</Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/inventory" className={classes.link}>Inventory</Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/dealer" className={classes.link}>Dealer</Link>
            </ListItemText>
          </ListItem>
          <Divider />
           <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/product" className={classes.link}>Product Availability</Link>
            </ListItemText>
          </ListItem>
          <Divider />
           <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/messages" className={classes.link}>Messages</Link>
            </ListItemText>
          </ListItem>
          <Divider />
           <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/ordersync" className={classes.link}>Order Sync</Link>
            </ListItemText>
          </ListItem>
          <Divider />
      
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/logout" className={classes.link}>
                Logout
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)} className={classes.icon}>
        <MenuIcon />
      </IconButton>
    </>
  );
}
