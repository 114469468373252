import authAxios from "src/modules/shared/axios/authAxios";
import { AuthToken } from "src/modules/auth/authToken";

export default class AuthService {
  static async signin(email, password) {
    const response = await authAxios.post('/auth/login', {
      email,
      password,
    });

    return response.data;
  }

  static async signout() {
    AuthToken.set(null, true);
  }

  static async fetchMe() {
    const response = await authAxios.get('/auth/me');
    return response.data;
  }
}
