import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { List, ListItem, makeStyles, Typography, Box } from "@material-ui/core";
import { ReactComponent as SkipPrevious } from "src/assets/img/previous.svg";
import authSelectors from "src/modules/auth/authSelectors";
import PermissionChecker from "src/modules/auth/permissionChecker";
import menus from "src/views/menus";
import styles from "src/assets/jss/views/menuStyle";
import { useState } from "react";

const useStyles = makeStyles(styles);

function Item(props: any) {
  const { children } = props;

  return <span style={{ marginLeft: "18px" }}>{children.toUpperCase()}</span>;
}

export default function Menu({ url }) {
  const classes = useStyles();
  const [mini, setMiniMode] = useState(false);
  const currentUser = useSelector(authSelectors.selectCurrentUser);

  const permissionChecker = new PermissionChecker(currentUser);

  const match = (permission) => {
    const hasPermission = permissionChecker.match(permission);
    return hasPermission;
  };
  const handleMiniMode = () => {
    setMiniMode(!mini);
  };
  return (
    <List className={`list ${mini ? "mini-mode" : ""}`}>
      <ListItem className="listitem">
        <Box className={classes.link + " menu-item"}>
          <Box className="menu-item-icon previous">
            <SkipPrevious onClick={handleMiniMode} />
          </Box>
        </Box>
      </ListItem>
      {menus
        .filter((menu) => match(menu.permissionRequired))
        .map((menu) => (
          <ListItem
            className={`${
              (menu.path === "/" && menu.path === url) || (menu.path !== "/" && "/" + url.split('/')[1] === menu.path)
                ? "active"
                : ""
            } listitem`}
            key={menu.path}
          >
            <Link to={menu.path} className={classes.link + " menu-item"}>
              <Box className="menu-item-icon">
                <menu.icon />
              </Box>
              <Typography component={Item}>{menu.label}</Typography>
            </Link>
          </ListItem>
        ))}
    </List>
  );
}
