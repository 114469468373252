class Roles {
  static get values() {
    return [
      {
        id: 1,
        name: "Admin",
      },
      {
        id: 5,
        name: "Customer Relations",
      },
      {
        id: 2,
        name: "Manager",
      },
      {
        id: 3,
        name: "Sales Rep",
      },
      {
        id: 4,
        name: "Warehouse"
      },
    ];
  }

  static getRoleById(id) {
    return this.values.find((item) => item.id === id);
  }

  static getRoleByName(name) {
    return this.values.find((item) => item.name === name);
  }
}

export default Roles;
