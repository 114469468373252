import DashboardIcon from '@material-ui/icons/Dashboard';
import PersonIcon from '@material-ui/icons/Person';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import InventoryIcon from '@material-ui/icons/TableChart';
import LoopIcon from '@material-ui/icons/Loop';
import Permissions from 'src/security/permissions';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import RedeemIcon from '@material-ui/icons/Redeem';
import MessageIcon from '@material-ui/icons/Message';
import MenuBook from '@material-ui/icons/MenuBook';
import RefreshIcon from '@material-ui/icons/Refresh';
import StoreIcon from '@material-ui/icons/Store';
import VisiblityIcon from '@material-ui/icons/Visibility';

// import LoopIcon from '@material-ui/icons/Loop';
const permissions = Permissions.values;

export default [
  {
    path: '/',
    exact: true,
    icon: DashboardIcon,
    label: 'Dashboard',
    permssionRequired: null,
  },
  {
    path: '/user',
    label: 'Users',
    icon: PersonIcon,
    permissionRequired: permissions[1],
  },
  {
    path: '/order',
    label: 'Orders',
    icon: ShoppingBasketIcon,
    permissionRequired: permissions[2],
  },
  {
    path: '/inventory',
    label: 'Inventory',
    icon: InventoryIcon,
    permissionRequired: permissions[3],
  },
  {
    path: '/dealer',
    label: 'Dealer Access',
    icon: PeopleAltIcon,
    permissionRequired: permissions[4],
  },
  {
    path: '/store-location',
    label: 'Store Locations',
    icon: StoreIcon,
    permissionRequired: permissions[10],
  },
  {
    path: '/product',
    label: 'Product Availability',
    icon: RedeemIcon,
    permissionRequired: permissions[5],
  },
  {
    path: '/product-visibility',
    label: 'Product Visibility',
    icon: VisiblityIcon,
    permissionRequired: permissions[8],
  },
  {
    path: '/product-data-refresh',
    label: 'Product Data Refresh',
    icon: RefreshIcon,
    permissionRequired: permissions[9],
  },
  {
    path: '/ordersync',
    label: 'Order Sync',
    icon: LoopIcon,
    permissionRequired: permissions[6],
  },
  {
    path: '/messages',
    label: 'Messages',
    icon: MessageIcon,
    permissionRequired: permissions[7],
  },
].filter(Boolean);
