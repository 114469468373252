import {
  h1Font,
  h3Font,
  h5Font,
  h6Font,
  body1Font,
  primaryColor,
  secondaryColor,
} from "src/assets/jss/variables";

const menuStyle = (theme) => ({
  root: {
    backgroundColor: "#fff",
  },
  title: {
    ...h1Font,
    color: primaryColor[0],
  },
  subtitle: {
    ...h5Font,
    color: primaryColor[0],
  },
  paper: {
    padding: theme.spacing(2),
  },
  logout: {
    color: secondaryColor[0],
  },
  main: {
    padding: "40px",
    backgroundColor: primaryColor[2],
  },
  blogTitle: {
    ...h3Font
  },
  blogDescription: {
    ...body1Font,
    marginBottom: "30px",
  },
  learnMore: {
    ...h6Font,
    backgroundColor: secondaryColor[0],
    borderRadius: 0,
    padding: "6px 12px",
    marginBottom: "40px",
    color: "white",
  },
  link: {
    ...body1Font,
    color: primaryColor[0],
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
  }
});

export default menuStyle;
