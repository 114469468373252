import {
  Box,
  Container,
  makeStyles,
  Typography,
} from "@material-ui/core";

import styles from "src/assets/jss/views/footerStyle";

const useStyles = makeStyles(styles);

export default function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Container maxWidth="lg" className={classes.footerWrap}>
        <Box className={classes.navLinks} pt={4}>
             <Typography className={classes.copyRight}>Copyright © {new Date().getFullYear()} Temple Fork Outfitters™ All Rights Reserved</Typography>
        </Box>
      </Container>
    </div>
  );
}
