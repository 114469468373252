import authAxios from "src/modules/shared/axios/authAxios";

export default class UserService {
  static async fetchUsers() {
    const response = await authAxios.get('/users');
    return response.data;
  }

  static async createUser(userData) {
    const response = await authAxios.post('/users', userData);
    return response.data;
  }

  static updateUser(id, userData) {
    return authAxios.put('/users/' + id, userData);
  }

  static deleteUser(id) {
    return authAxios.delete('/users/' + id)
  }
}
