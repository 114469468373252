import actions from "src/modules/auth/authActions";

const initialData = {
  loadingInit: true,
  loading: false,
  currentUser: null,
  error: null,
};

const authReducers = (state = initialData, { type, payload }) => {
  if (type === actions.AUTH_START) {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }

  if (type === actions.AUTH_SUCCESS) {
    return {
      ...state,
      currentUser: payload.currentUser || null,
      error: null,
      loading: false,
    };
  }

  if (type === actions.AUTH_ERROR) {
    return {
      ...state,
      currentUser: null,
      error: payload || null,
      loading: false,
    };
  }

  if (type === actions.AUTH_INIT_SUCCESS) {
    return {
      ...state,
      currentUser: payload.currentUser || null,
      loadingInit: false,
    };
  }

  if (type === actions.AUTH_INIT_ERROR) {
    return {
      ...state,
      currentUser: null,
      loadingInit: false,
    };
  }

  return state;
};

export default authReducers;
