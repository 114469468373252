import Permissions from "src/security/permissions";

const permissions = Permissions.values;
const privateRoutes = [
  {
    path: "/",
    loader: () => import("src/views/dashboard/DashboardPage"),
    permissionRequired: null,
    exact: true,
  },
  {
    path: "/user",
    loader: () => import("src/views/user/UserListPage"),
    permissionRequired: permissions[1],
    exact: true,
  },
  {
    path: "/user/create",
    loader: () => import("src/views/user/UserCreatePage"),
    permissionRequired: permissions[1],
    exact: true,
  },
  {
    path: "/user/edit",
    loader: () => import("src/views/user/UserCreatePage"),
    permissionRequired: permissions[1],
    exact: true,
  },
  {
    path: "/order",
    loader: () => import("src/views/order/OrderPage"),
    permissionRequired: permissions[2],
    exact: true,
  },
  {
    path: "/inventory",
    loader: () => import("src/views/inventory/InventoryPage"),
    permissionRequired: permissions[3],
    exact: true,
  },
  {
    path: "/dealer",
    loader: () => import("src/views/dealer/DealerPage"),
    permissionRequired: permissions[4],
    exact: true,
  },
  {
    path: "/store-location",
    loader: () => import("src/views/store-location/StoreLocationPage"),
    permissionRequired: permissions[10],
    exact: true,
  },
  {
    path: "/store-location/create",
    loader: () => import("src/views/store-location/StoreLocationCreatePage"),
    permissionRequired: permissions[10],
    exact: true,
  },
  {
    path: "/store-location/edit",
    loader: () => import("src/views/store-location/StoreLocationCreatePage"),
    permissionRequired: permissions[10],
    exact: true,
  },
  {
    path: "/product",
    loader: () => import("src/views/product/ProductAvailabilityPage"),
    permissionRequired: permissions[5],
    exact: true,
  },
  {
    path: "/product-visibility",
    loader: () => import("src/views/product/ProductVisibilityPage"),
    permissionRequired: permissions[8],
    exact: true,
  },
  {
    path: "/product-data-refresh",
    loader: () => import("src/views/product/ProductDataRefreshPage"),
    permissionRequired: permissions[9],
    exact: true,
  },
  {
    path: "/ordersync",
    loader: () => import("src/views/order/OrderSyncPage"),
    permissionRequired: permissions[6],
    exact: true,
  },
  {
    path: "/messages",
    loader: () => import("src/views/message/MessagePage"),
    permissionRequired: permissions[7],
    exact: true,
  },
  {
    path: "/messages/create",
    loader: () => import("src/views/message/MessageCreatePage"),
    permissionRequired: permissions[7],
    exact: true,
  },
  {
    path: "/messages/edit",
    loader: () => import("src/views/message/MessageCreatePage"),
    permissionRequired: permissions[7],
    exact: true,
  },
  {
    path: "/resources",
    loader: () => import("src/views/resources/Resources"),
    permissionRequired: null,
    exact: true,
  },
  {
    path: "/logout",
    loader: () => import("src/views/auth/SignoutPage"),
    permissionRequired: null,
    exact: true,
  },
].filter(Boolean);

const publicRoutes = [
  {
    path: "/auth/signin",
    loader: () => import("src/views/auth/SigninPage"),
  },
].filter(Boolean);

const simpleRoutes = [
  {
    path: "/403",
    loader: () => import("src/views/shared/errors/Error403Page"),
  },
  {
    path: "/500",
    loader: () => import("src/views/shared/errors/Error500Page"),
  },
  // {
  //   path: "**",
  //   loader: () => import("src/views/shared/errors/Error404Page"),
  // },
].filter(Boolean);

const routes = {
  privateRoutes,
  publicRoutes,
  simpleRoutes,
};

export default routes;
