import {
  primaryColor,
  h7Font,
} from "src/assets/jss/variables";

const drawerStyle = {
  list: {
    backgroundColor: primaryColor[2],
    padding: "30px 20px 0",
    height: "100vh",
  },
  link: {
    ...h7Font,
    color: "#000",
    textDecoration: "none",
  },
  icon: {
    color: "#fff",
  }
};

export default drawerStyle;
