import { AuthToken } from 'src/modules/auth/authToken';
import Axios from 'axios';
import { backendUrl } from 'src/config';
import Qs from 'qs';
import moment from 'moment';

const authAxios = Axios.create({
  baseURL: backendUrl,
  paramsSerializer: function (params) {
    return Qs.stringify(params, {
      arrayFormat: 'brackets',
      filter: (prefix, value) => {
        if (moment.isMoment(value) || value instanceof Date) {
          return value.toISOString();
        }

        return value;
      },
    });
  },
});

authAxios.interceptors.request.use(
  async function (options: any) {
    const token = AuthToken.get();

    if (token) {
      options.headers['Authorization'] = `Bearer ${token}`;
    }

    return options;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default authAxios;
