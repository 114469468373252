import { Redirect, Route } from "react-router-dom";
import PermissionChecker from "src/modules/auth/permissionChecker";

export default function PublicRoute({
  component: Component,
  currentUser,
  ...rest
}) {

  return (
    <Route
      {...rest}
      render={(props) => {
        const permissionChecker = new PermissionChecker(
          currentUser,
        );

        if (permissionChecker.isAuthenticated) {
          return <Redirect to="/" />;
        }

        return <Component {...props} />;
      }}
    />
  );
}
