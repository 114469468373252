const primaryColor = ["#141414", "#787878", "#dcdcdc"];
const secondaryColor = ["#fd5b05", "#1572c7"];
const dangerColor = ["#ee5959"];
const warningColor = ["#eeb359"];
const infoColor = ["#157cb7"];
const successColor = ["#66c774"];
const grayColor = ["#666666", "#999999", "#dbdbdb"];
const roseColor = ["#e91e63", "#ec407a", "#d81b60", "#eb3573", "#c2185b"];
const blackColor = "#000";
const darkColor = "#333333";
const whiteColor = "#ffffff";

const hexToRgb = input => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase(input);
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return (
    parseInt(first, 16) +
    ", " +
    parseInt(second, 16) +
    ", " +
    parseInt(last, 16)
  );
}

const container = {
  paddingRight: "15px",
  paddingLeft: "15px",
  marginRight: "auto",
  marginLeft: "auto",
  "@media (min-width: 768px)": {
    width: "750px"
  },
  "@media (min-width: 992px)": {
    width: "970px"
  },
  "@media (min-width: 1200px)": {
    width: "1170px"
  },
  "&:before,&:after": {
    display: "table",
    content: ""
  },
  "&:after": {
    clear: "both"
  }
};

const title = {
  color: grayColor[2],
  textDecoration: "none",
  fontWeight: 700,
  marginTop: "30px",
  "& small": {
    color: grayColor[1],
    fontSize: "65%",
    fontWeight: 400,
    lineHeight: 1
  }
};

const cardTitle = {
  ...title,
  marginTop: 0,
  marginBottom: "3px",
  minHeight: "auto",
  "& a": {
    ...title,
    marginTop: ".625rem",
    marginBottom: "0.75rem",
    minHeight: "auto"
  }
};

const h1Font = {
  fontFamily: "Trade Gothic LT",
  fontSize: "68px",
  fontWeight: 700,
  lineHeight: "70px",
};
const h2Font = {
  fontFamily: "Trade Gothic LT",
  fontSize: "50px",
  fontWeight: 700,
  lineHeight: "50px",
};
const h3Font = {
  fontFamily: "Trade Gothic LT",
  fontSize: "38px",
  fontWeight: 700,
  lineHeight: "40px",
};
const h4Font = {
  fontFamily: "Trade Gothic LT",
  fontSize: "28px",
  fontWeight: 700,
  lineHeight: "30px",
};
const h5Font = {
  fontFamily: "Trade Gothic LT",
  fontSize: "21px",
  fontWeight: 700,
  lineHeight: "24px",
};
const h6Font = {
  fontFamily: "Trade Gothic LT",
  fontSize: "18px",
  fontWeight: 700,
  lineHeight: "22px",
};
const h7Font = {
  fontFamily: "Trade Gothic LT",
  fontSize: "16px",
  fontWeight: 700,
  lineHeight: "21px",
};
const body1Font = {
  fontFamily: "Open Sans Condensed Light",
  fontSize: "21px",
  fontWeight: 300,
  lineHeight: "32px",
};
const body2Font = {
  fontFamily: "Open Sans Condensed Light",
  fontSize: "18px",
  fontWeight: 300,
  lineHeight: "24px",
};
const body3Font = {
  fontFamily: "Open Sans Condensed Light",
  fontSize: "16px",
  fontWeight: 300,
  lineHeight: "20px",
};

const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(" +
    hexToRgb(blackColor) +
    ", 0.42), 0 4px 25px 0px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)"
};

const primaryBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(primaryColor[0]) +
    ",.4)"
};
const infoBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(infoColor[0]) +
    ",.4)"
};
const successBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(successColor[0]) +
    ",.4)"
};
const warningBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(warningColor[0]) +
    ",.4)"
};
const dangerBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(dangerColor[0]) +
    ",.4)"
};
const roseBoxShadow = {
  boxShadow:
    "0 4px 20px 0 rgba(" +
    hexToRgb(blackColor) +
    ",.14), 0 7px 10px -5px rgba(" +
    hexToRgb(roseColor[0]) +
    ",.4)"
};

export {
  hexToRgb,
  container,
  cardTitle,
  primaryColor,
  secondaryColor,
  grayColor,
  dangerColor,
  warningColor,
  infoColor,
  successColor,
  blackColor,
  darkColor,
  whiteColor,
  roseColor,
  h1Font,
  h2Font,
  h3Font,
  h4Font,
  h5Font,
  h6Font,
  h7Font,
  body1Font,
  body2Font,
  body3Font,
  boxShadow,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
};
