import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { configureStore, getHistory } from 'src/modules/store'
import RoutesComponent from './views/shared/routes/RoutesComponent'

import 'src/assets/scss/styles.scss'

const store = configureStore()

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={getHistory()}>
        <RoutesComponent />
      </ConnectedRouter>
    </Provider>
  )
}

export default App
