import {
  primaryColor,
  secondaryColor,
  body2Font,
} from "src/assets/jss/variables";

const footerStyle = {
  footer: {
    padding: "0 50px 30px",
    borderTop: "1px solid #DCDCDC",
    marginTop: "auto",
  },
  footerWrap: {
    display: "flex",
    flexWrap: "wrap" as const,
    justifyContent: "space-between",
    maxWidth: "inherit",
    padding: 0,
  },
  navLinks: {
    display: "flex",
    flexWrap: "wrap" as const,
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 768px)": {
      width: "100%",
      justifyContent: "center",
      paddingTop: "15px",
      textAlign: `center`,
    }
  },
  link: {
    ...body2Font,
    color: primaryColor[0],
    textDecoration: "none",
    marginLeft: "40px",
    "@media (max-width: 768px)": {
      marginLeft: "10px",
      marginRight: "10px",
    },
    '&:focus,&:hover': {
      color: secondaryColor[0]
    },
  },
  copyRight: {
    ...body2Font,
    color: primaryColor[0],
  }
};

export default footerStyle;
