import React from "react";
import { useEffect, useRef } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { CssBaseline } from "@material-ui/core";

import authSelectors from "src/modules/auth/authSelectors";
import routes from "src/views/routes";
import CustomLoadable from "src/views/shared/CustomLoadable";
import PrivateRoute from "src/views/shared/routes/PrivateRoute";
import PublicRoute from "src/views/shared/routes/PublicRoute";
import ProgressBar from "src/views/shared/ProgressBar";

export default function RoutesComponent() {
  const isInitialMount = useRef(true);
  
  const currentUser = useSelector(
    authSelectors.selectCurrentUser,
  );

  const loading = useSelector(
    authSelectors.selectLoadingInit,
  );

  useEffect(() => {
    if (isInitialMount.current && loading) {
      isInitialMount.current = false;
      ProgressBar.start();
      return;
    }

    if (!loading) {
      ProgressBar.done();
    }
  }, [loading]);

  if (loading) {
    return <div />;
  }

  return (
    <BrowserRouter>
      <Switch>
        <React.Fragment>
          <CssBaseline />
          
          {routes.publicRoutes.map((route) => (
            <PublicRoute
              key={route.path}
              exact
              path={route.path}
              currentUser={currentUser}
              component={CustomLoadable({
                loader: route.loader,
              })}
            />
          ))}

          {routes.privateRoutes.map((route) => (
            <PrivateRoute
              key={route.path}
              currentUser={currentUser}
              permissionRequired={route.permissionRequired}
              path={route.path}
              component={CustomLoadable({
                loader: route.loader,
              })}
              exact={Boolean(route.exact)}
            />
          ))}
          
          {routes.simpleRoutes.map((route) => (
            <Route
              key={route.path}
              exact
              path={route.path}
              component={CustomLoadable({
                loader: route.loader,
              })}
            />
          ))}
        </React.Fragment>
      </Switch>
    </BrowserRouter>
  );
}
