import {h5Font, h7Font, secondaryColor, whiteColor} from "src/assets/jss/variables";

const headerStyle = (theme) => ({
  navbar: {
    minHeight: "80px",
    backgroundColor: "#000",
    justifyContent: "center",
    boxShadow: "none",
    "@media (max-width: 992px)": {
      minHeight: "inherit"
    }
  },
  toolbar: {
    padding: "0 0 0 45px",
    "@media (max-width: 959px)": {
      padding: "0",
      justifyContent: "space-between"
    }
  },
  logoWrap: {
    width: "200px",
    display: "flex",
  },
  logo: {
    height: "60px",
    display: 'inline-block',
    verticalAlign: 'top',

    "@media (max-width: 959px)": {
      height: "40px",
      marginLeft: `20px`
    }
  },
  navbox: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "right",
    }
  },
  navlinks: {
    display: "flex",
    width: "100%"
  },

  link: {
    ...h5Font,
    color: "#fff",
    marginLeft: theme.spacing(5),
    textDecoration: "none",
    width: "130px",
    backgroundColor: "#222222",
    padding: "16px 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 300ms ease",
    '&:focus,&:hover': {
      color: secondaryColor[0]
    },
  },
  iconlink: {
    color: "#fff",
    marginLeft: theme.spacing(2),
  },
  author: {
    ...h7Font,
    color: secondaryColor[0],
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  authorIcon: {
    marginRight: "8px",
    color: whiteColor
  },
  description: {
    ...h5Font,
    color: secondaryColor[0],
    display: "flex",
    marginLeft: "auto",
    alignItems: "center"
  },

  logout: {
    ...h7Font,
    color: whiteColor,
    textDecoration: "none",
    marginLeft: "20px",
    padding: "30px 20px 30px 18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#222222",
    transition: "all 300ms ease",
    '&:focus,&:hover': {
      color: secondaryColor[0]
    },
  },
  icon: {
    marginRight: "8px"
  }
});

export default headerStyle;
