export default class PermissionChecker {
  currentUser;

  constructor(currentUser) {
    this.currentUser = currentUser;
  }

  match(permission) {

    if (!permission) {
      return true;
    }

    if (!this.currentUser.role) {
      return false;
    }

    const userPermissions = this.currentUser.permissions || [];
    const hasPermission = !!userPermissions.find((item) => item.id === permission.id);

    return hasPermission;
  }

  get isAuthenticated() {
    return (
      Boolean(this.currentUser) &&
      Boolean(this.currentUser.email)
    );
  }

}
