import { connectRouter } from "connected-react-router";
import { combineReducers } from "redux";

import auth from "src/modules/auth/authReducers";
import user from "src/modules/user/userReducers";

const reducers = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    user,
  });

export default reducers;
